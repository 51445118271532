<template>
  <div id="layout">
    <el-container>
      <el-aside width="220px" style="background-color: #2d3c49">
        <div id="logo">浩云运营后台</div>
        <el-menu
          style="border-right: none"
          :default-active="this.$route.path"
          background-color="#2d3c49"
          text-color="#fff"
          active-text-color="#ffd04b"
          unique-opened
          :router="true"
        >
          <!-- <el-menu-item index="/home"> 首页 </el-menu-item> -->
          <el-menu-item index="/tenant"> 租户管理 </el-menu-item>
          <el-menu-item index="/user"> 用户管理 </el-menu-item>
          <el-menu-item index="/group"> 群组管理 </el-menu-item>
          <el-menu-item index="/invite">邀请管理</el-menu-item>
          <el-menu-item index="/content"> 内容审核 </el-menu-item>
          <el-menu-item index="/complaint"> 投诉管理 </el-menu-item>
          <el-menu-item index="/feedback"> 反馈管理 </el-menu-item>
          <el-menu-item index="/game"> 游戏管理 </el-menu-item>
          <el-menu-item
            index="/application"
            v-if="objInformation.is_super === -1"
          >
            应用管理
          </el-menu-item>
          <el-menu-item index="/version"> 版本管理 </el-menu-item>
          <el-menu-item index="/setting"> 配置管理 </el-menu-item>
          <el-submenu index="/record">
            <template slot="title">日志管理</template>
            <el-menu-item index="/record/login">登录日志</el-menu-item>
            <el-menu-item index="/record/handle">操作日志</el-menu-item>
            <el-menu-item index="/record/api">接口日志</el-menu-item>
          </el-submenu>
        </el-menu>
      </el-aside>
      <el-container>
        <el-header style="padding: 0 20px 0 0">
          <div id="profile-dropdown">
            <el-dropdown @command="handleCommand">
              <div class="el-dropdown-link">
                {{ objInformation.username }}
              </div>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="change-password">
                  <i class="iconfont icon-user"></i>修改密码
                </el-dropdown-item>
                <el-dropdown-item
                  command="admin"
                  v-if="objInformation.is_super === 1"
                >
                  <i class="iconfont icon-setting"></i>人员设置
                </el-dropdown-item>
                <el-dropdown-item command="logout" divided>
                  <i class="iconfont icon-logout"></i>退出登录
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </el-header>
        <el-main>
          <router-view />
        </el-main>
        <el-footer height="36px">
          浩云 &copy; 2019 - {{ currentYear }}
        </el-footer>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import dayjs from "dayjs";

import account from "@/api/account";

import { mapState } from "vuex";

export default {
  name: "layout",
  data() {
    return {
      currentYear: dayjs().year(),
      activeIndex: "/home",
      name: "",
    };
  },
  computed: {
    ...mapState({
      objInformation: (state) => state.objInformation,
    }),
  },
  created() {
    this.handleInit();
  },
  methods: {
    handleInit() {
      account.getInformation().then((res) => {
        this.$store.commit("handleInitInformation", res.results);
      });
    },
    handleCommand(name) {
      if (name === "change-password") {
        this.$prompt("密码", "修改密码", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          inputType: "password",
          inputPattern: /^.*(?=.{6,})(?=.*\d)(?=.*[a-z]).*$/,
          inputErrorMessage: "至少6位，且包含字母和数字",
        }).then(({ value }) => {
          let data = {
            password: value,
          };
          account.changePassword(data).then(() => {
            this.$router.push({ name: "logout" });
          });
        });
      } else {
        this.$router.push({ name: name });
      }
    },
  },
};
</script>

<style lang="stylus">
#layout
  min-height: 100vh;
  overflow-x: hidden;

#logo
  height: 60px;
  background-color: #1d2533;
  color: #ffffff;
  text-align: center;
  line-height: 60px;
  font-size: 24px;
  font-weight: bold;

#profile-dropdown
  text-align: center;
  width: 60px;
  float: right;
  height: 60px;
  line-height: 60px;
  cursor: pointer;

#profile-dropdown:hover
  background-color: #daf6ff;
</style>
